// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAFsOcqHyA_DOgt4VJO_6EW60vo8TpBorY",
  authDomain: "zabran-scrum-board.firebaseapp.com",
  projectId: "zabran-scrum-board",
  storageBucket: "zabran-scrum-board.appspot.com",
  messagingSenderId: "122489386144",
  appId: "1:122489386144:web:435c52585cb57736bfc5c5",
  measurementId: "G-T5KRQXWS4W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted"){
        const token = await getToken(messaging, {
            vapidKey: "BJmZ-jMALwS2zJ5bLJhZHVvQ8ySYHts3LeESiBI6ixstwVCL_1BApBrzUTB16PURCFucH0gCwM_Xf5qvAAd4Wq8",
        });

        return token;
    } else {
        return "";
    }
};