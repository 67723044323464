import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Alert,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
import boardTypeApi from "../api/boardTypeApi";
import boardApi from "../api/boardApi";
import userApi from "../api/userApi";
import sectionApi from "../api/sectionApi";
import iconApi from "../api/iconApi";
import speedIconApi from "../api/speedIconApi";
import EmojiPicker from "../components/common/EmojiPicker";
import Kanban from "../components/common/Kanban";
import { setBoards } from "../redux/features/boardSlice";
import { setFavouriteList } from "../redux/features/favouriteSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Moment from "moment";
import UserBoardModal from "../components/common/UserBoardModal";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import assets from "../assets/index";
import tagApi from "../api/tagApi";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import ReportModal from "../components/common/ReportModal";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

let timer;
const timeout = 500;

const CheckMarkSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 0,
    width: 0,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .checkmark-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 5,
    background:
      theme.palette.mode === "dark"
        ? "linear-gradient(-45deg, #ccc 40%, #333 50%, #ccc 60%)"
        : "linear-gradient(-45deg, #ccc 40%, #999 50%, #ccc 60%)",
    backgroundSize: "300%",
    backgroundPositionX: "100%",
    animation: "shimmer 5s infinite linear",
  },
  "@keyframes shimmer": {
    to: {
      backgroundPositionX: "0%",
    },
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

const CheckMarkThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {/* <Typography sx={{ transform: "scale(-1, 1)", fontSize: "20px" }}>
        {other.ownerState.icon}
      </Typography> */}
      <Typography sx={{ marginLeft: "-25px" }}>
        <span
          style={{
            transform: "scale(-1, 1)",
            fontSize: "30px",
            position: "absolute",
          }}
        >
          {other.ownerState.icon}
        </span>
        <br />
        {other.ownerState.value !== 100 &&
        other.ownerState.value !== 0 &&
        other.ownerState.value !== NaN &&
        other.ownerState.value !== null &&
        other.ownerState.value !== undefined ? (
          <span
            style={{
              transform: "unset",
              fontSize: "12px",
              position: "relative",
              top: "8px",
            }}
          >
            {other.ownerState.value && other.ownerState.value.toFixed(1)}%
          </span>
        ) : (
          <span
            style={{
              transform: "unset",
              fontSize: "12px",
              position: "relative",
              top: "8px",
            }}
          >
            &nbsp;
          </span>
        )}
      </Typography>
    </SliderThumb>
  );
};

const Board = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const searchQuery = useOutletContext();
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { boardId } = useParams();
  const [users, setUsers] = useState([]);
  const [progTeam, setProgTeam] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [progTeamNum, setProgTeamNum] = useState(0);
  const [progTeamIcon, setProgTeamIcon] = useState("");
  const [title, setTitle] = useState("");
  const [boardUser, setBoardUser] = useState("");
  const [background, setBackground] = useState("");
  const [description, setDescription] = useState("");
  const [boardType, setBoardType] = useState("");
  const [boardReward, setBoardReward] = useState("");
  const [boardTypeName, setBoardTypeName] = useState("");
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStartDateProgress, setBoardStartDateProgress] = useState(
    Moment()
  );
  const [boardDueDateProgress, setBoardDueDateProgress] = useState(Moment());
  const [boardStartDate, setBoardStartDate] = useState(Moment());
  const [boardDueDate, setBoardDueDate] = useState(Moment());
  const [sections, setSections] = useState([]);
  const [isFavourite, setIsFavourite] = useState(false);
  const [icon, setIcon] = useState("");
  const [icons, setIcons] = useState([]);
  const [speedIcons, setSpeedIcons] = useState([]);
  const [selectedModal, setSelectedModal] = useState(undefined);
  const [selectedReportModal, setSelectedReportModal] = useState(undefined);
  const [openDialogCreateSection, setOpenDialogCreateSection] = useState(false);
  const [tags, setTags] = useState([]);
  const [alertDueDate, setAlertDueDate] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuDashboard = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDialogCreateSection = () => {
    setOpenDialogCreateSection(true);
  };

  const [openDialogDeleteBoard, setOpenDialogDeleteBoard] = useState(false);

  const showDialogDeleteBoard = () => {
    setOpenDialogDeleteBoard(true);
  };

  const hideDialog = () => {
    setOpenDialogDeleteBoard(false);
    setOpenDialogCreateSection(false);
  };

  const boards = useSelector((state) => state.board.value);
  const favouriteList = useSelector((state) => state.favourites.value);

  useEffect(() => {
    const getBoardTypes = async () => {
      try {
        const res = await boardTypeApi.getAll();
        setBoardTypes(res);
      } catch (err) {
        console.log(err);
      }
    };
    getBoardTypes();
  }, []);

  useEffect(() => {
    const getIcons = async () => {
      try {
        const res = await iconApi.getAll();
        setIcons(res);
      } catch (err) {
        console.log(err);
      }
    };
    getIcons();
  }, []);

  useEffect(() => {
    const getSpeedIcons = async () => {
      try {
        const res = await speedIconApi.getAll();
        setSpeedIcons(res);
      } catch (err) {
        console.log(err);
      }
    };
    getSpeedIcons();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (user.role.isManage === 1) {
      const getUsers = async () => {
        try {
          const res = await userApi.getAll(boardId);
          setUsers(res.users.sort((a, b) => b.progNum - a.progNum));
          setProgTeam(res.progTeam);
          setProgTeamIcon(res.progTeam.icon);
          setProgTeamNum(res.progTeam.num);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      };
      getUsers();
      const getAllUsers = async () => {
        try {
          const res = await userApi.getUsers();
          res.forEach((value, index) => {
            setAllUsers(res);
          });
        } catch (err) {
          console.log(err);
        }
      };
      getAllUsers();
      const getBoard = async () => {
        try {
          const res = await boardApi.getOne(boardId);
          setTitle(res.title);
          setBoardUser(res.user);
          setBackground(res.background);
          setDescription(res.description);
          setSections(res.sections);
          setBoardReward(res.reward !== null ? res.reward : "");
          if (res.boardType !== undefined) {
            setBoardType(res.boardType.id);
            setBoardTypeName(res.boardType.name);
            const resTags = await tagApi.getAll(res.boardType.id);
            setTags(resTags);
          } else {
            setBoardType("");
            setBoardTypeName("");
          }

          setBoardStartDateProgress(res.startDate);
          setBoardDueDateProgress(res.dueDate);
          setBoardDueDate(dayjs(res.dueDate));
          setBoardStartDate(dayjs(res.startDate));
          setIsFavourite(res.favourite);
          setIcon(res.icon);

          if (
            dayjs(res.dueDate).format("YYYY-MM-DD") <
            dayjs(Moment()).format("YYYY-MM-DD")
          ) {
            setAlertDueDate(true);
          } else {
            setAlertDueDate(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getBoard();
    } else {
      const getUsers = async () => {
        try {
          const res = await userApi.getAll(boardId);
          setUsers(res.users.sort((a, b) => b.progNum - a.progNum));
          setProgTeam(res.progTeam);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      };
      getUsers();
      const getAllUsers = async () => {
        try {
          const res = await userApi.getUsers();
          res.forEach((value, index) => {
            setAllUsers(res);
          });
        } catch (err) {
          console.log(err);
        }
      };
      getAllUsers();
      const getBoard = async () => {
        try {
          const res = await boardApi.getOne(boardId);
          setTitle(res.title);
          setBoardUser(res.user);
          setBackground(res.background);
          setDescription(res.description);
          setSections(res.sections);
          if (res.boardType !== undefined) {
            setBoardType(res.boardType.id);
            setBoardTypeName(res.boardType.name);
            const resTags = await tagApi.getAll(res.boardType.id);
            setTags(resTags);
          } else {
            setBoardType("");
            setBoardTypeName("");
          }
          setBoardStartDateProgress(res.startDate);
          setBoardDueDateProgress(res.dueDate);
          setBoardDueDate(dayjs(res.dueDate));
          setBoardStartDate(dayjs(res.startDate));
          setIsFavourite(res.favourite);
          setIcon(res.icon);
        } catch (err) {
          console.log(err);
        }
      };
      getBoard();
    }
  }, [boardId]);

  const onIconChange = async (newIcon) => {
    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], icon: newIcon };

    if (isFavourite) {
      let tempFavourite = [...favouriteList];
      const favouriteIndex = tempFavourite.findIndex((e) => e.id === boardId);
      tempFavourite[favouriteIndex] = {
        ...tempFavourite[favouriteIndex],
        icon: newIcon,
      };
      dispatch(setFavouriteList(tempFavourite));
    }

    setIcon(newIcon);
    dispatch(setBoards(temp));
    try {
      await boardApi.update(boardId, { icon: newIcon });
    } catch (err) {
      console.log(err);
    }
  };

  const updateTitle = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    setTitle(newTitle);

    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], title: newTitle };

    if (isFavourite) {
      let tempFavourite = [...favouriteList];
      const favouriteIndex = tempFavourite.findIndex((e) => e.id === boardId);
      tempFavourite[favouriteIndex] = {
        ...tempFavourite[favouriteIndex],
        title: newTitle,
      };
      dispatch(setFavouriteList(tempFavourite));
    }

    dispatch(setBoards(temp));

    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { title: newTitle });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateBoardType = async (e) => {
    clearTimeout(timer);
    const newBoardType = e.target.value;

    const boardTypesIndex = boardTypes.findIndex((e) => e.id === newBoardType);
    const currentBoardType = boardTypes[boardTypesIndex];
    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], boardType: currentBoardType };
    dispatch(setBoards(temp));

    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { boardType: newBoardType });
        setBoardType(newBoardType);
        const resTags = await tagApi.getAll(newBoardType);
        setTags(resTags);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateBoardStartDate = async (e) => {
    clearTimeout(timer);
    const newStartDate = new Date(e);
    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { startDate: newStartDate });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    setBoardStartDate(dayjs(newStartDate));
  };

  const updateBoardDueDate = async (e) => {
    clearTimeout(timer);
    const newDueDate = new Date(e);
    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, {
          dueDate: newDueDate,
        });
        setBoardDueDate(dayjs(newDueDate));
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateDescription = async (e) => {
    clearTimeout(timer);
    const newDescription = e.target.value;
    setDescription(newDescription);
    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { description: newDescription });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const deleteBoard = async () => {
    try {
      await boardApi.delete(boardId);
      if (isFavourite) {
        const newFavouriteList = favouriteList.filter((e) => e.id !== boardId);
        dispatch(setFavouriteList(newFavouriteList));
      }

      const newList = boards.filter((e) => e.id !== boardId);
      if (newList.length === 0) {
        navigate("/");
      } else {
        navigate(`/boards/${newList[0].id}`);
      }
      dispatch(setBoards(newList));
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateUsers = (users) => {
    setUsers([...users]);
  };

  const onUpdateUsersProgTeam = (users, progTeam) => {
    setUsers([...users]);
    setProgTeam(progTeam);
    setProgTeamIcon(progTeam.icon);
    setProgTeamNum(progTeam.num);
  };

  const onUpdateSections = (sections) => {
    setSections([...sections]);
  };

  const onUpdateTags = (tags) => {
    setTags([...tags]);
  };

  const onUpdateProgTeam = (num, icon) => {
    setProgTeamNum(num);
    setProgTeamIcon(icon);
  };

  const createSection = async () => {
    try {
      const section = await sectionApi.create(boardId);
      let jsonSection = [...sections, section];
      let sortedJsonSection = jsonSection.sort(
        (a, b) => new Date(a.isDone) - new Date(b.isDone)
      );
      // console.log(sortedJsonSection);
      setSections(sortedJsonSection);
      users.forEach((value) => {
        value.sections.push({
          board: section.board,
          id: section.id,
          tasks: [],
          isDone: section.isDone,
          title: "",
          __v: 0,
          _id: section.id,
        });
        value.sections.sort((a, b) => new Date(a.isDone) - new Date(b.isDone));
      });
      setUsers([...users]);
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {openDialogCreateSection && (
        <Dialog
          open={openDialogCreateSection}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure create section
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={createSection} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openDialogDeleteBoard && (
        <Dialog
          open={openDialogDeleteBoard}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
            Are you sure delete board
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete board
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteBoard} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {loading === true && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            backgroundColor: theme.palette.mode === "dark" ? "black" : "white",
            width: "100%",
            height: "100%",
            zIndex: "9",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.5",
            margin: "-10px",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          background:
            background === undefined || background === ""
              ? theme.palette.mode === "dark"
                ? assets.colors.mainDarkBg
                : assets.colors.mainLightBg
              : "url(" + background + ") fixed",
          minHeight: "100%",
          backgroundRepeat: "noRepeat",
          WebkitBackgroundSize: "cover",
          MozBackgroundSize: "cover",
          OBackgroundSize: "cover",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            background:
              theme.palette.mode === "dark"
                ? assets.colors.DarkBgOpacity8
                : assets.colors.LightBgOpacity8,
            padding: "10px 20px",
          }}
        >
          {alertDueDate && (
            <Alert
              sx={{ mb: 3 }}
              severity="error"
              onClose={() => {
                setAlertDueDate(false);
              }}
            >
              Board has expired.
            </Alert>
          )}
          {user.role.isManage === 1 || boardUser === user.id ? (
            <>
              <Grid
                container
                spacing={2}
                justify="center"
                sx={{
                  display: "flex",
                  paddingRight: "16px",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  "& .MuiGrid-item": {
                    paddingTop: "8px",
                  },
                }}
                className="grid-board"
              >
                <Grid item xs={12} sm={12} sx={{ padding: "0px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color:
                          theme.palette.mode === "dark"
                            ? assets.colors.taskTextDark
                            : assets.colors.taskTextLight,
                      }}
                    >
                      0%
                    </span>
                    <CheckMarkSlider
                      sx={{ margin: "0 15px" }}
                      value={progTeamNum}
                      icon={progTeamIcon}
                      disabled
                      components={{ Thumb: CheckMarkThumbComponent }}
                    />
                    <span
                      style={{
                        color:
                          theme.palette.mode === "dark"
                            ? assets.colors.taskTextDark
                            : assets.colors.taskTextLight,
                      }}
                    >
                      100%
                    </span>
                    {boardReward !== "" && (
                      <Box
                        sx={{
                          width: "150px",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                          fontWeight: 700,
                        }}
                      >
                        {/* <EmojiEventsIcon sx={{ marginRight: "3px" }} /> */}
                        {boardReward.icon} {boardReward.name}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ padding: "0px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <EmojiPicker icon={icon} onChange={onIconChange} />
                    <TextField
                      value={title}
                      onChange={updateTitle}
                      placeholder="Untitled"
                      variant="outlined"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-input": { padding: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "unset ",
                        },
                        "& .MuiOutlinedInput-root": {
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.boardTextDark
                              : assets.colors.boardTextLight,
                        },
                      }}
                    />
                  </Box>
                  <TextField
                    value={description}
                    onChange={updateDescription}
                    placeholder="Add a description"
                    variant="outlined"
                    multiline
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": { padding: 0 },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "unset ",
                      },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "0.8rem",
                        padding: "0px 0px 0px 0px",
                        color:
                          theme.palette.mode === "dark"
                            ? assets.colors.boardTextDark
                            : assets.colors.boardTextLight,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      spacing={0}
                      justify="center"
                      sx={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "flex-end",
                        "& .MuiGrid-item": {
                          paddingTop: "8px",
                        },
                      }}
                      className="grid-board"
                    >
                      <Grid item xs={12} sm={3}>
                        <AvatarGroup max={5} sx={{ mr: 1, justifyContent: "center", ml: 1, }}>
                          {users.map((item, index) => (
                            <Tooltip
                              key={`avatarBoard-${index}`}
                              title={item.name}
                            >
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <Typography>{item.mood.icon}</Typography>
                                }
                                sx={{
                                  WebkitUserSelect: "none",
                                  MsUserSelect: "none",
                                  userSelect: "none",
                                  "& .MuiAvatar-root": {
                                    border:
                                      item.stAcc === "login"
                                        ? "3px solid green !important"
                                        : "3px solid #121212 !important",
                                  },
                                }}
                              >
                                <Avatar
                                  alt={item.name}
                                  src={item.image}
                                  sx={{
                                    "&:hover": {
                                      opacity: 0.9,
                                      backgroundColor: "#000",
                                    },
                                  }}
                                />
                              </Badge>
                            </Tooltip>
                          ))}
                        </AvatarGroup>
                      </Grid>
                      <Grid item xs={12} sm={3} sx={{display: "flex", justifyContent: "center"}}>
                        {dayjs(boardDueDate).format("YYYY-MM-DD") >=
                          dayjs(Moment()).format("YYYY-MM-DD") && (
                          <Tooltip title="Add / Delete Users">
                            <Button
                              onClick={() => {
                                setSelectedModal(boardId);
                              }}
                              variant="contained"
                              color="secondary"
                              disableElevation={true}
                              sx={{
                                mr: 1,
                                padding: "8px 10px",
                                minWidth: "unset",
                              }}
                            >
                              <PeopleIcon />
                            </Button>
                          </Tooltip>
                        )}
                        {dayjs(boardDueDate).format("YYYY-MM-DD") >=
                          dayjs(Moment()).format("YYYY-MM-DD") &&
                          users.length !== 0 && (
                            <Tooltip title="Add Section">
                              <Button
                                onClick={showDialogCreateSection}
                                variant="contained"
                                color="primary"
                                disableElevation={true}
                                sx={{
                                  mr: 1,
                                  padding: "8px 10px",
                                  minWidth: "unset",
                                }}
                              >
                                <PlaylistAddIcon />
                              </Button>
                            </Tooltip>
                          )}
                        <Tooltip title="Dashboard">
                          <Button
                            id="basic-button"
                            variant="contained"
                            sx={{
                              mr: 1,
                              padding: "8px 10px",
                              minWidth: "unset",
                            }}
                            color="success"
                            disableElevation={true}
                            aria-controls={
                              openMenuDashboard ? "basic-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              openMenuDashboard ? "true" : undefined
                            }
                            onClick={handleClick}
                          >
                            <DashboardIcon />
                          </Button>
                        </Tooltip>
                        {user.role.isManage === 1 && (
                          <Tooltip title="Delete Board">
                            <Button
                              variant="contained"
                              sx={{
                                mr: 1,
                                padding: "8px 10px",
                                minWidth: "unset",
                              }}
                              color="error"
                              disableElevation={true}
                              onClick={showDialogDeleteBoard}
                            >
                              <DeleteOutlinedIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </Grid>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenuDashboard}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to={`/dashboard/${boardId}`}
                        >
                          Sprint Management
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to={`/dashboard_overview/${boardId}`}
                        >
                          Team Overview
                        </MenuItem>
                      </Menu>
                      <Grid item xs={4} sm={2}>
                        <Box sx={{ mr: 1 }}>
                          <p
                            style={{
                              fontSize: "14px",
                              color:
                                theme.palette.mode === "dark"
                                  ? assets.colors.boardTextDark
                                  : assets.colors.boardTextLight,
                              margin: "0px",
                            }}
                          >
                            Start Date
                          </p>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={boardStartDate}
                              onChange={updateBoardStartDate}
                              dateFormat="dd/MM/yyyy"
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                              className="toolbar-board"
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <Box sx={{ mr: 1 }}>
                          <p
                            style={{
                              fontSize: "14px",
                              color:
                                theme.palette.mode === "dark"
                                  ? assets.colors.boardTextDark
                                  : assets.colors.boardTextLight,
                              margin: "0px",
                            }}
                          >
                            Due Date
                          </p>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={boardDueDate}
                              onChange={updateBoardDueDate}
                              dateFormat="dd/MM/yyyy"
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                              className="toolbar-board"
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <Box sx={{ mr: 1 }}>
                          <p
                            style={{
                              fontSize: "14px",
                              color:
                                theme.palette.mode === "dark"
                                  ? assets.colors.boardTextDark
                                  : assets.colors.boardTextLight,
                              margin: "0px",
                            }}
                          >
                            Division
                          </p>
                          <FormControl
                            size="small"
                            sx={{
                              width: "100%",
                              "& .MuiOutlinedInput-input": {
                                padding: "8.5px 0px 8.5px 8.5px",
                              },
                              "& .MuiFormControl-root": {
                                background:
                                  theme.palette.mode === "dark"
                                    ? assets.colors.inputTypeDark
                                    : assets.colors.inputTypeLight,
                              },
                              "& .MuiOutlinedInput-root": {
                                background:
                                  theme.palette.mode === "dark"
                                    ? assets.colors.inputTypeDark
                                    : assets.colors.inputTypeLight,
                              },
                            }}
                            className="toolbar-board"
                          >
                            <Select
                              labelId="label-position"
                              name="boardType"
                              fullWidth
                              value={boardType}
                              id="boardType"
                              onChange={updateBoardType}
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiFormControl-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                                "& .MuiInputBase-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                            >
                              {boardTypes.map((boardType, index) => (
                                <MenuItem key={index} value={boardType.id}>
                                  {boardType.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                spacing={2}
                justify="center"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  "& .MuiGrid-item": {
                    paddingTop: "8px",
                  },
                }}
              >
                <Grid item xs={12} sm={4} sx={{ padding: "0px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <EmojiPicker icon={icon} />
                    <Typography
                      sx={{
                        padding: 0,
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color:
                          theme.palette.mode === "dark"
                            ? assets.colors.boardTextDark
                            : assets.colors.boardTextLight,
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      padding: "0px 0px 0px 0px",
                      color:
                        theme.palette.mode === "dark"
                          ? assets.colors.boardTextDark
                          : assets.colors.boardTextLight,
                    }}
                  >
                    {description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <AvatarGroup max={10} sx={{ mr: 2 }}>
                      {users.map((item, index) => (
                        <Tooltip key={`avatarBoard-${index}`} title={item.name}>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <Typography>{item.mood.icon}</Typography>
                            }
                            sx={{
                              WebkitUserSelect: "none",
                              MsUserSelect: "none",
                              userSelect: "none",
                              "& .MuiAvatar-root": {
                                border:
                                  item.stAcc === "login"
                                    ? "3px solid green !important"
                                    : "3px solid #121212 !important",
                              },
                            }}
                          >
                            <Avatar
                              alt={item.name}
                              src={item.image}
                              sx={{
                                "&:hover": {
                                  opacity: 0.9,
                                  backgroundColor: "#000",
                                },
                              }}
                            />
                          </Badge>
                        </Tooltip>
                      ))}
                    </AvatarGroup>

                    <Box sx={{ mr: 2 }}>
                      <p
                        style={{
                          fontSize: "14px",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.boardTextDark
                              : assets.colors.boardTextLight,
                          margin: "0px",
                        }}
                      >
                        Start Date
                      </p>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.boardTextDark
                              : assets.colors.boardTextLight,
                        }}
                      >
                        {dayjs(boardStartDate).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                    <Box sx={{ mr: 2 }}>
                      <p
                        style={{
                          fontSize: "14px",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.boardTextDark
                              : assets.colors.boardTextLight,
                          margin: "0px",
                        }}
                      >
                        Due Date
                      </p>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.boardTextDark
                              : assets.colors.boardTextLight,
                        }}
                      >
                        {dayjs(boardDueDate).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                    <Box sx={{ mr: 2 }}>
                      <p
                        style={{
                          fontSize: "14px",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.boardTextDark
                              : assets.colors.boardTextLight,
                          margin: "0px",
                        }}
                      >
                        Division
                      </p>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.boardTextDark
                              : assets.colors.boardTextLight,
                        }}
                      >
                        {boardTypeName !== "" ? boardTypeName : "No Board Type"}
                      </Typography>
                    </Box>
                    {/* {user.role.isManage === 1 && (
                      <IconButton variant="outlined" onClick={addFavourite}>
                        {isFavourite ? (
                          <StarOutlinedIcon color="warning" />
                        ) : (
                          <StarBorderOutlinedIcon />
                        )}
                      </IconButton>
                    )} */}
                    {user.role.isManage === 1 && (
                      <Tooltip title="Delete Board">
                        <Button
                          variant="contained"
                          sx={{ mr: 1, padding: "8px 10px", minWidth: "unset" }}
                          color="error"
                          disableElevation={true}
                          onClick={showDialogDeleteBoard}
                        >
                          <DeleteOutlinedIcon />
                        </Button>
                      </Tooltip>
                    )}
                    {/* {user.role !== "admin" && (
                      <IconButton
                        variant="outlined"
                        color="error"
                        sx={{ height: "25px" }}
                      ></IconButton>
                    )} */}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Box sx={{ padding: "10px 20px" }}>
          {/* Kanban board */}
          <Kanban
            onUpdateProgTeam={onUpdateProgTeam}
            icons={icons}
            speedIcons={speedIcons}
            data={sections}
            boardId={boardId}
            tags={tags}
            boardUser={boardUser}
            boardType={boardType}
            boardStartDateProgress={boardStartDateProgress}
            boardDueDateProgress={boardDueDateProgress}
            boardDueDate={boardDueDate}
            users={users}
            progTeam={progTeam}
            searchQuery={searchQuery}
            onUpdate={onUpdateSections}
            onUpdateTags={onUpdateTags}
            onUpdateUsers={onUpdateUsersProgTeam}
          />
        </Box>
      </Box>
      <UserBoardModal
        boardId={selectedModal}
        users={users}
        allUsers={allUsers}
        onUpdate={onUpdateUsers}
        onClose={() => setSelectedModal(undefined)}
      />

      <ReportModal
        boardId={selectedReportModal}
        users={users}
        allUsers={allUsers}
        onUpdate={onUpdateUsers}
        onClose={() => setSelectedReportModal(undefined)}
      />
    </>
  );
};

export default Board;
